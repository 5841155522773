import React from 'react';
import './header.css';


function Header() {
  return (
    <div className="header">
            <a href='/'>Food Recipes</a>
            <a className='Home' href='/'>Home</a>
    </div>
  );
}

export default Header;
